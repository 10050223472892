<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 681.64 630.62">
    <path
      fill="currentColor"
      stroke="0"
      d="M361.87,298.41c-4.72,5.27-9.1,10.88-13.29,16.58-2.8,3.82-5.97,5.56-10.89,6.02-24.02,2.26-39.74,22.33-36.18,45.93.74,4.89-.91,8.35-3.82,11.98-22.89,28.64-45.66,57.37-68.46,86.08-1.67,2.11-3.34,4.22-4.88,6.42-1.18,1.69-1.78,3.56-.16,5.35,1.86,2.06,3.93,1.58,5.92.24,1.41-.96,2.74-2.04,4.08-3.1,27.23-21.54,54.46-43.09,81.7-64.62,7.17-5.67,12-10.81,22.73-8.6,20.93.11,38.45-12.3,40.69-31.03,1.34-11.22,7.17-16.74,14.81-22.44,2.94-2.19,5.81-4.49,8.62-6.84,9.05-7.56,9.92-17.44,1.93-26.21-5.43-5.96-11.18-11.65-17.1-17.12-8.56-7.89-17.87-7.41-25.71,1.35ZM357.48,360.25c.35,8.96-7.5,16.96-16.6,16.92-9.01-.04-16.22-7.23-16.29-16.22-.07-9.43,6.48-16.09,15.97-16.22,9.35-.13,16.57,6.49,16.92,15.53Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M340.69,146.23c6.15.04,10.06-3.37,11.16-9.44.65-3.57.67-7.24,1-11.18-.35-3.6-.44-7.52-1.16-11.32-1.09-5.74-5.01-9.16-10.66-9.23-6.01-.07-10.05,3.47-11.13,9.55-1.33,7.48-1.34,15.04.13,22.5,1.12,5.72,4.83,9.07,10.67,9.11Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M145.97,341.68c.12-5.87-3.09-9.72-8.77-10.95-7.67-1.65-15.46-1.63-23.16-.12-5.73,1.12-9.12,5.08-9.17,10.73-.05,6.03,3.54,10.02,9.59,11.11,3.56.65,7.23.71,11,1.04,3.75-.33,7.67-.36,11.46-1.08,5.74-1.09,8.93-4.94,9.05-10.74Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M566.91,330.44c-7.48-1.22-15.03-1.36-22.45.29-5.68,1.26-8.88,5.13-8.75,10.97.13,5.77,3.32,9.66,9.07,10.73,3.79.71,7.71.74,11.2,1.04,3.79-.29,7.21-.32,10.55-.85,6.33-1.01,10.34-4.89,10.27-11.3-.06-6.02-3.83-9.89-9.89-10.88Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M457.77,136.23c-4.73-1.96-9.38-1.36-12.6,2.69-4.85,6.1-8.85,12.87-11.72,20.11-1.98,4.99-.39,9.65,4.07,12.92,3.97,2.91,8.6,3.34,12.19.19,7.67-6.75,11.18-16.19,14.8-24.18-.26-6.11-2.21-9.85-6.75-11.73Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M513.97,248.11c8.61,4.59,32.45-9.29,32.64-19.01.16-8.11-7.43-13.8-14.97-10.49-6.64,2.91-13.09,6.55-19,10.74-7.09,5.02-6.09,14.8,1.34,18.76Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M158.61,434.33c-7.04,2.68-13.54,6.7-19.49,11.32-4.89,3.79-5.51,9.15-2.37,14.4,3.02,5.04,8.01,6.77,13.24,4.36,6.59-3.04,12.86-6.85,19.05-10.68,3.27-2.02,4.65-5.51,4.75-8.87-.25-7.96-7.87-13.3-15.19-10.52Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M521.01,433.58c-6.85.09-10.34,2.66-12.26,7.43-2,4.99-.59,9.54,3.52,12.32,6.21,4.21,12.82,7.93,19.59,11.16,4.6,2.2,9.19.71,12.38-3.38,3.14-4.03,3.9-9.05.57-12.85-6.7-7.64-15.93-11.49-23.79-14.67Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M237.94,174.11c6.81-.04,12.6-6.83,10.59-13.65-2.44-8.24-6.87-15.78-12.66-22.1-5.06-5.51-13.78-3.62-17.32,3.02-4.49,8.43,9.94,32.77,19.39,32.72Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M147.58,217.57c-6.3.3-9.83,2.71-11.68,7.14-2.02,4.82-1,9.41,3.08,12.59,5.98,4.67,12.6,8.51,19.62,11.34,4.98,2.01,9.69.53,13-3.98,2.93-4,3.43-8.72.2-12.26-6.88-7.54-16.17-11.4-24.21-14.82Z"
    />
    <path
      fill="currentColor"
      stroke="0"
      d="M151.94,523.3c-2.13-1.67-4.18-3.32-6.13-4.94-36.25-39.64-58.64-87.22-65.84-143.13C62.1,236.33,154.11,112.66,284.79,83.83c18.45-4.07,37.14-5.8,56.02-5.74,99.87-1.8,197.87,61.01,238.97,152.25,15.65,34.73,24.51,70.99,24.27,108.8-.45,70.15-23.21,130.14-68.24,179.25-.77,1.66-1.67,3.29-2.67,4.9h96.26c33.1-52.65,52.25-115.02,52.24-181.93C681.62,152.82,529.25.07,341.11,0,152.87-.07.06,152.6,0,340.81c-.02,67.13,19.16,129.7,52.32,182.49h99.62Z"
    />
    <g>
      <rect
        fill="currentColor"
        stroke="0"
        x="162.75"
        y="485.73"
        width="72.79"
        height="144.89"
        rx="8"
        ry="8"
      />
      <rect
        fill="currentColor"
        stroke="0"
        x="259.34"
        y="485.73"
        width="72.79"
        height="144.89"
        rx="8"
        ry="8"
      />
      <rect
        fill="currentColor"
        stroke="0"
        x="355.93"
        y="485.73"
        width="72.79"
        height="144.89"
        rx="8"
        ry="8"
      />
      <rect
        fill="currentColor"
        stroke="0"
        x="452.52"
        y="485.73"
        width="72.79"
        height="144.89"
        rx="8"
        ry="8"
      />
    </g>
  </svg>
</template>
